<template>
	<section>
		<b-row class="mb-2">
		    <b-col>
				<Breadcrumb :labels="labels" />				
		    </b-col>		    
		</b-row>		
		<b-row>
		    <b-col md="12">
		    	<div class="relative">
			        <b-tabs card class="caption-add table-custom">                       
			            <b-tab title="Basic Info" active class="overview-tab">	
			            	<validation-observer
						        ref="form_rel"
						        #default="{invalid}" >
				                <b-form @submit.prevent class="profile-frm mb-20" style="border-radius: 5px">
				                	<b-row class="align-center">
							            <b-col lg="6" style="padding-left: 33px ">
							                <h2 class="mb-2"><strong>Basic Info</strong></h2>
							            </b-col>
							            <b-col lg-6>
							            	<div class="text-right mb-2">
							                    <b-button type="button" variant="basic" class="btn-df size-18 black" @click="cancelAction" style="box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25); margin-right: 10px ">Cancel</b-button>

							                    <template v-if="(userData && permission.create)">

								                    <b-button type="button" variant="primary" class="btn-df size-18 black" @click="saveAction">
								                        Save
								                    </b-button>

								                </template>
								                <template v-else>
								                	<b-button type="button" variant="primary" class="btn-df size-18 black btn-disabled-gray" disabled>
								                        Save
								                    </b-button>
								                </template>
							                </div>
							            </b-col>
							        </b-row>				                
					                <div style="border-top: 1px solid #000000B2;" class="pb-2 mb-10"></div>
					                <div class="frm-basic size-16 black-child d-block">
				                      	<b-row style="width: 100%; margin: 0;">   
				                          	<b-col lg="12" class="px-0">             
					                            <b-form-group label="Product Name" label-cols-lg="2" class="star-required input-group-label">    
					                            	<validation-provider
						                              	#default="{ errors }"
						                              	name="Product Name"
						                              	rules="required"                
						                            >                        
					                                    <b-form-input
					                                    	v-model="fields.name"
					                                      	placeholder="Name"
					                                      	:formatter="length100"
					                                    ></b-form-input>
					                                    <small class="text-danger">{{ errors[0] }}</small>
					                                </validation-provider>
				                              	</b-form-group>
				                          	</b-col>			                          	
				                          	<b-col lg="12" class="px-0">
					                            <b-form-group label="Description" label-cols-lg="2" class="star-required">
					                            	<validation-provider
						                              	#default="{ errors }"
						                              	name="Description"
						                              	rules="required"                  
						                            > 
						                            	<b-form-textarea
						                            		id="textarea-state"
						                            		v-model="fields.description"
						                                    placeholder="Description"
						                                    rows="4"
						                                    :formatter="length1000"
															style="padding-top: 12px !important"
						                                ></b-form-textarea>
						                                <small class="text-danger">{{ errors[0] }}</small>
						                            </validation-provider>
					                            </b-form-group>
					                            <div style="border-top: 1px solid #B1B1B1; height: 14px; "></div>
				                          	</b-col> 
				                          	<b-col lg="12" class="px-0 cb-aligncenter cb-aligncenter-y10">  
				                          		<b-form-group label="Services" label-cols-lg="2" class="star-required">
				                          			<validation-provider
						                              	#default="{ errors }"
						                              	name="Services"
						                              	rules="required"                  
						                            > 
						                          		<b-form-checkbox-group
														    v-model="selectedServices"
													        :options="optionsServices"
													        name="cb-services"
													        value-field="id"
    														text-field="name"
														>
														</b-form-checkbox-group>
														<small class="text-danger">{{ errors[0] }}</small>
						                            </validation-provider>
												</b-form-group>
				                          	</b-col>   
				                      	</b-row>
					                </div>
					            </b-form> 
					        </validation-observer>
			            </b-tab>
			            <b-tab title="Sub-topics" disabled>
			            </b-tab>
			            <b-tab title="Documents" class="tab-child tab-pt-0" disabled></b-tab>
			        </b-tabs>     
			    </div>
			</b-col>
		</b-row>
	</section>
</template>
<script>

	import Breadcrumb from '../partial/Breadcrumb';
	import flatPickr from 'vue-flatpickr-component'
    import vSelect from 'vue-select'
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import { required, email, integer, min } from '@validations'
    import { togglePasswordVisibility } from '@core/mixins/ui/forms'
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

	export default {
	  	components: {
	  		Breadcrumb,
	  		flatPickr,
          	vSelect,
          	ValidationProvider,
          	ValidationObserver,
	  	},
	  	data() {
		    return {
				name:"Product",
		    	userData: JSON.parse(localStorage.getItem('userData')),
		    	searchQuery: null,
				labels: { "our-portfolio-project-detail": "" },    
			    selectedServices: [],
		        optionsServices: [],
				permission:[],
		        fields: {
		        	name: null,
		        	description: ''
		        },
            }
		},	
		created(){
			this.listServicesSetup()
			this.checkPermission()
		},
		methods: {
			checkPermission(){
				var action = [];
				var namePage = this.name;
				this.userData?.permission.map(function (value) {
					if(value.module === namePage){
						action = value.actions
					}
					
				})
				this.permission = action 
			},
			listServicesSetup(){
				this.$store
				.dispatch('auth/fetchServicesSetup', {	
					filter: {
					  "list_search": [
					    {
					      "field": "status",
					      "keyword": 1
					    }
					  ]
					}
				})
				.then(response => {
					this.optionsServices = response.data.data
					// console.log('optionsServices: ', response.data.data)
				})
				.catch((error) => {
					console.log(error)
				})
			},
			cancelAction(){
				this.$router.push({ name: 'itees-product' })
			},
			saveAction(){
				this.$refs.form_rel.validate().then(success => {
                	if(success){
                		let datas = {
							name: this.fields.name,
							description: this.fields.description,
							service: this.selectedServices
						}
						// console.log('data: ', datas)
						this.$store
						.dispatch('project/addProduct', datas)
						.then(response => {
							this.$toast({
			                      	component: ToastificationContent,
			                      	props: {
			                          title: this.fields.name + ' Created!',
			                          variant: 'success',
			                      	},
			                	},
			                	{
			                      	position: 'top-center'
			                	}
			                )
			                this.$router.push({ name: 'itees-product' })
						})
						.catch((error) => {
							console.log(error)
							this.$toast({
		                      	component: ToastificationContent,
		                      	props: {
		                        	title: error.response.data.message,
		                        	variant: 'danger',
		                      	},
		                    },
		                    {
		                      position: 'top-center'
		                    })
						})
                	}
                	else{
		            	 this.$toast({
	                        component: ToastificationContent,
	                        props: {
	                          title: 'One or more fields have an error. Please check and try again.',
	                          variant: 'danger',
	                        },
	                      },
	                      {
	                        position: 'top-center'
	                      }
	                    )
		            }
                })
			},
          	length1000(e){
			    return String(e).substring(0,1000)
			},
			length100(e){
			    return String(e).substring(0,100)
			},
		},
	};	

</script>

<style lang="scss">
	@import "@/assets/scss/_ite_tab_table.scss";
	
	.Vue-Toastification__container.top-center {
	    top: 20px; 
	}

</style>